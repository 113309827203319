import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { instanceToPlain, plainToInstance } from "class-transformer";
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Role } from '../auth/models/role.model';
import { Market } from '../company/modules/commercial/models/market.model';
import { Action } from './models/action.model';
import { Module } from './models/module.model';
import { Permission } from './models/permission.model';

@Injectable()
export class AuthorizationService {

  private permissionsUrl = '/:apiBase/admin/authorization/permissions';
  private permissionUrl = '/:apiBase/admin/authorization/permissions/:permissionId';
  private rolesUrl = '/:apiBase/admin/roles';
  private modulesUrl = '/:apiBase/admin/modules';
  private actionsUrl = '/:apiBase/admin/actions';
  private marketsUrl = '/:apiBase/admin/markets';

  constructor(
    private http: HttpClient
  ) { }

  public getPermissions(): Observable<Permission[]> {
    return this.http.get<Permission[]>(this.permissionsUrl).pipe(
      map(permissions => plainToInstance(Permission, permissions))
    );
  }

  public getModules(): Observable<Module[]> {
    return this.http.get<Module[]>(this.modulesUrl);
  }

  public getActions(): Observable<Action[]> {
    return this.http.get<Action[]>(this.actionsUrl).pipe(
      map(actions => plainToInstance(Action, actions))
    );
  }

  public getMarkets(): Observable<Market[]> {
    const url = this.marketsUrl;
    return this.http.get<Market[]>(url).pipe(
      map(markets => plainToInstance(Market, markets))
    );
  }

  public getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(this.rolesUrl);
  }

  public updatePermission(permission: Permission): Observable<any> {
    const data = instanceToPlain(permission);
    if (permission.group_module_action.id) {
      const url = this.permissionUrl.replace(':permissionId', permission.group_module_action.id.toString());
      return this.http.put<any>(url, data);
    } else {
      const url = this.permissionsUrl;
      return this.http.post<any>(url, data).pipe(
        tap(response => {
          permission.group_module_action.id = response.id;
        })
      );
    }
  }
}
